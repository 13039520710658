// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {ChildrenType} from '../types';

// The content section between the top nav and footer
// TODO: It probably makes sense to create a ScreenContent or similar wrapper for all content, move the vertical padding styles there
const GridContainerDefault = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  section {
    margin: 0 auto;
    max-width: ${dimensions.grid.containerWidth.sm}px;
    ${mediaQueries.MIN_MD} {
      max-width: ${dimensions.grid.containerWidth.md}px;
    }
    ${mediaQueries.MIN_LG} {
      max-width: ${dimensions.grid.containerWidth.lg}px;
    }
    ${mediaQueries.MIN_XL} {
      max-width: ${dimensions.grid.containerWidth.xl}px;
    }
    ${mediaQueries.MIN_XXL} {
      max-width: ${dimensions.grid.containerWidth.xxl}px;
    }

    padding: ${dimensions.spacing.small} 0;
    ${mediaQueries.MIN_MD} {
      padding: ${dimensions.spacing.vertical.medium} 0;
    }
    ${mediaQueries.MIN_XL} {
      // padding: ${dimensions.spacing.vertical.large} 0;
    }
  }
`;

const GridContainerFullScreen = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
`;

class GridContainer extends React.Component {
  render() {
    const {variant, children} = this.props;

    if (variant === 'fullScreen') {
      return (
        <GridContainerFullScreen {...this.props}>
          {children}
        </GridContainerFullScreen>
      );
    }

    return (
      <GridContainerDefault {...this.props}>{children}</GridContainerDefault>
    );
  }
}

GridContainer.defaultProps = {
  variant: 'default',
};

GridContainer.propTypes = {
  variant: PropTypes.oneOf(['default', 'fullScreen']),
  children: ChildrenType.isRequired,
};

export default GridContainer;
