// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

import {ChildrenType, PageMetaType} from '../types';

import Footer from './Footer';
import GridContainer from './GridContainer';
import Nav from './Nav';
import SiteMetadata from './SiteMetadata';

const systemFontFamily = 'Georgia, serif';

const GlobalStyle = createGlobalStyle`
  body {
    padding-top: constant(safe-area-inset-top, 0);
    padding-top: env(safe-area-inset-top, 0);

    &:not(.wf-proxima-nova--loaded),
    &:not(.wf-freight-text-pro--loaded) {
      // font-family: ${systemFontFamily};
    }

    &.wf-proxima-nova--loaded,
    &.wf-freight-text-pro--loaded {
    }
  }
`;

// The entire page wrapper, to ensure the Footer is all the way at the bottom of the screen when there is little content
const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

class Layout extends React.Component {
  render() {
    const {path, pageMeta, children, isFullScreenWithNav, isHome} = this.props;

    const renderNav = () => {
      return <Nav path={path} hidesStrapline={isHome} />;
    };

    if (isFullScreenWithNav) {
      return (
        <React.Fragment>
          <GlobalStyle />
          <SiteMetadata path={path} pageMeta={pageMeta} />
          {renderNav()}
          <GridContainer variant="fullScreen">{children}</GridContainer>
          <Footer />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <GlobalStyle />
        <SiteMetadata path={path} pageMeta={pageMeta} />
        <PageWrapper>
          {renderNav()}
          <GridContainer>{children}</GridContainer>
          <Footer />
        </PageWrapper>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: ChildrenType.isRequired,
  isFullScreenWithNav: PropTypes.bool,
  path: PropTypes.string.isRequired,
  pageMeta: PageMetaType,
  isHome: PropTypes.bool,
};

export default Layout;
