// @flow
import {useStaticQuery, graphql} from 'gatsby';

export const useSiteMetadata = () => {
  const {site, dato} = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
            description
            siteUrl
            legalAddress
            legalName
          }
        }
        dato {
          _site {
            globalSeo {
              siteName
              twitterAccount
              fallbackSeo {
                description
                image {
                  ...imageFields
                }
              }
            }
            faviconMetaTags {
              tag
              attributes
            }
          }
        }
      }
    `,
  );

  // By parsing like this, we make it significantly easier to understand the context of data when consuming it inside the layout components
  return {
    gatsby: site.siteMetadata,
    dato: dato._site.globalSeo,
    favicons: dato._site.faviconMetaTags,
  };
};
