// @flow
import {Link as GatsbyLink} from 'gatsby';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import React from 'react';
import 'react-modal-video/css/modal-video.min.css';
import '../theme/modalVideoOverride.css';
import ModalVideo from 'react-modal-video';

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openVideoModal = (e, videoId) => {
    if (!videoId) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (this.state.isOpen) {
      return;
    }
    this.setState({
      isOpen: true,
    });
    noScroll.on(); // Prevent the viewport from scrolling behind the modal
  };

  closeVideoModal = () => {
    this.setState({isOpen: false});
    noScroll.off(); // Re-enable viewport scrolling, since the modal has been dismissed
  };

  render() {
    const {children, to, video, ...other} = this.props;

    const isFileLink = /\.[0-9a-z]+$/i.test(to); // File links can't be handled by Gatsby link, so we look for them by inspecting for an extension
    const isInternalRelativeLink = /^\/(?!\/)/.test(to); // We assume that any internal link (intended for Gatsby) will start with exactly one slash, and that anything else is external.
    const isInternalQualifiedLink = /https?:\/\/hanno\.co/i.test(to); // A link which doesn't start with a "/", but still contains hanno.co (and is either http or https)

    const canShowVideo = !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );

    if (video && canShowVideo) {
      // We only want to output the video if the browser can support it; otherwise we'll just link to the Vimeo/YouTube site

      return (
        <a
          onClick={(e) => this.openVideoModal(e, video.providerUid)}
          target="_blank"
          rel="noopener noreferrer"
          href={video.url}
          {...other}
        >
          {children}
          <ModalVideo
            // See supported props: https://www.npmjs.com/package/react-modal-video
            youtube={{autoplay: 1}}
            vimeo={{
              // Access any API property by adding it to this object: https://developer.vimeo.com/api/oembed/videos
              autoplay: true, // NB: Will not work on every device, every time
              texttrack: 'en', // Will show the captions if available
              dnt: true, // Disable tracking
              responsive: true, // Responsive, for better mobile support
            }}
            isOpen={this.state.isOpen}
            onClose={this.closeVideoModal}
            channel={video.provider}
            videoId={video.providerUid}
          />
        </a>
      );
    }

    // Use Gatsby Link for internal links, unless they are to files
    if (isInternalRelativeLink) {
      // File links are internal, but we can't route to them with Gatsby Link, so they need to be hrefs
      if (isFileLink) {
        return (
          <a href={to} target="_blank" rel="noreferrer" {...other}>
            {children}
          </a>
        );
      }

      // If the internal link doesn't point to a file, it must be a valid internal Gatsby link.
      // We add a trailing slash (if one doesn't exist), which ensures consistency across the site and should play nicely with `gatsby-plugin-force-trailing-slashes` plugin and works nicely with Netlify's handling of URLs
      let linkTo = to;
      if (linkTo.substr(-1) != '/') linkTo += '/';

      return (
        <GatsbyLink to={linkTo} {...other}>
          {children}
        </GatsbyLink>
      );
    }

    // Internal qualified links don't need to open in a new tab, but we can't handle them via Gatsby Links
    if (isInternalQualifiedLink) {
      return (
        <a href={to} {...other}>
          {children}
        </a>
      );
    }

    // Everything else must be an external link, and we'll open it in a new tab
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    providerUid: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
