// @flow
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Link from './Link';

// Custom renderers can be added as per: https://www.npmjs.com/package/react-markdown#use-custom-renderers-syntax-highlight
const renderers = {
  // This uses our custom Link component, which handles opening articles in new tabs and using react routing for internal links
  link: ({href, children}) => {
    return <Link to={href}>{children}</Link>;
  },
};

class Markdown extends React.Component {
  render() {
    const {content, allowHtml} = this.props;

    return (
      <ReactMarkdown
        allowDangerousHtml={allowHtml}
        renderers={renderers}
        source={content}
      />
    );
  }
}

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  allowHtml: PropTypes.bool,
};

export default Markdown;
