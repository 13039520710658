// @flow
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {ChildrenType} from '../types';

const GridRowDefault = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;

  &.justify-center {
    ${mediaQueries.MIN_MD} {
      justify-content: center;
    }
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
`;

const GridRowExpanded = styled(GridRowDefault)`
  ${mediaQueries.MIN_LG} {
    padding-left: ${dimensions.spacing.default};
    padding-right: ${dimensions.spacing.default};
  }
  ${mediaQueries.MIN_XL} {
    padding-left: ${dimensions.spacing.horizontal.large};
    padding-right: ${dimensions.spacing.horizontal.large};
  }
`;

class Row extends React.Component {
  render() {
    const {
      theme,
      children,
      noGutter,
      justifyCenter,
      justifyBetween,
      alignCenter,
    } = this.props;

    let className = classNames({
      'no-gutter': noGutter,
      'justify-center': justifyCenter,
      'justify-between': justifyBetween,
      'align-center': alignCenter,
    });

    if (theme === 'expanded') {
      return (
        <GridRowExpanded className={className} {...this.props}>
          {children}
        </GridRowExpanded>
      );
    }

    return (
      <GridRowDefault className={className} {...this.props}>
        {children}
      </GridRowDefault>
    );
  }
}

Row.defaultProps = {
  theme: 'default',
  noGutter: false,
  justifyCenter: false,
  justifyBetween: false,
  alignCenter: false,
};

Row.propTypes = {
  theme: PropTypes.oneOf(['default', 'expanded']),
  noGutter: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyBetween: PropTypes.bool,
  alignCenter: PropTypes.bool,
  children: ChildrenType.isRequired,
};

export default Row;
