// @flow
import PropTypes from 'prop-types';

export const ImageSourceType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  focalPoint: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  blurhash: PropTypes.string,
}).isRequired;

// eslint-disable-next-line sort-exports/sort-exports
export const AuthorType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    imageSource: ImageSourceType,
  }),
).isRequired;

export const ChildrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.element,
  PropTypes.node,
]);

export const ImageModifiersType = PropTypes.shape({
  alt: PropTypes.string, // Allows override of the inherited version from Dato
  aspectRatio: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  caption: PropTypes.string,
  className: PropTypes.string,
  crop: PropTypes.oneOf(['faces', 'focalpoint']),
  isSquare: PropTypes.bool,
  borderRadius: PropTypes.string,
  hideBlurhash: PropTypes.bool, // Used rarely, just to hide our own Blurhash in contexts that we know will look quite bad
}).isRequired;

// eslint-disable-next-line sort-exports/sort-exports
export const ImageFullObjectType = PropTypes.shape({
  imageSource: ImageSourceType,
  imageModifiers: ImageModifiersType,
});

export const ItemMetaType = PropTypes.shape({
  primary: PropTypes.string,
  primaryLink: PropTypes.string,
  secondary: PropTypes.string,
});

export const LocationType = PropTypes.shape({
  path: PropTypes.string,
});

export const PageMetaType = PropTypes.shape({
  strings: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    rss: PropTypes.object,
  }),
  dato: PropTypes.array,
}).isRequired;

export const PathType = PropTypes.string.isRequired;
