// @flow
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import {ChildrenType} from '../types';

const displayValue = (props) => (props.inline ? 'inline-flex' : 'inherit');

const StyledVisible = styled.span`
  display: none;

  &.default {
    display: ${displayValue};
  }

  &.visible-xs {
    ${mediaQueries.MAX_SM} {
      display: ${displayValue};
    }
  }

  &.visible-sm {
    ${mediaQueries.SM_ONLY} {
      display: ${displayValue};
    }
  }

  &.visible-md {
    ${mediaQueries.MD_ONLY} {
      display: ${displayValue};
    }
  }

  &.visible-lg {
    ${mediaQueries.LG_ONLY} {
      display: ${displayValue};
    }
  }

  &.visible-xl {
    ${mediaQueries.MIN_XL} {
      display: ${displayValue};
    }
  }
`;

class Visible extends React.Component {
  render() {
    const {children, breakpoints} = this.props;

    let className = classNames({
      'visible-xs': breakpoints.includes('xs'),
      'visible-sm': breakpoints.includes('sm'),
      'visible-md': breakpoints.includes('md'),
      'visible-lg': breakpoints.includes('lg'),
      'visible-xl': breakpoints.includes('xl'),
    });

    return (
      <StyledVisible className={className || 'default'} {...this.props}>
        {children}
      </StyledVisible>
    );
  }
}

Visible.propTypes = {
  children: ChildrenType.isRequired,
  breakpoints: PropTypes.string,
};

export default Visible;
