// @flow
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import {colors} from '../theme/colors';
import dimensions from '../theme/dimensions';

import Col from './Col';
import MenuLinks from './MenuLinks';
import Row from './Row';

const StyledRow = styled(Row)`
  background-color: ${colors.offWhite};
  padding: ${dimensions.spacing.default} 0;
  ${mediaQueries.MIN_LG} {
    padding: ${dimensions.spacing.default};
  }
  ${mediaQueries.MIN_XL} {
    padding: ${dimensions.spacing.default}
      ${dimensions.spacing.horizontal.large};
  }

  display: none;
  ${mediaQueries.MIN_MD} {
    display: flex;
  }
`;

const Footer = () => (
  <footer>
    <StyledRow>
      <Col>
        <MenuLinks variant="secondary" />
      </Col>
    </StyledRow>
  </footer>
);

export default Footer;
