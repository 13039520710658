// @flow
import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {ChildrenType} from '../types';

const colSize = {
  8: '100%',
  7: '87.5%',
  6: '75%',
  5: '62.5%',
  4: '50%',
  3: '37.5%',
  2: '25%',
  1: '12.5%',
};

const GridCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  min-height: 1px;

  padding-left: ${dimensions.grid.gutter / 2}px;
  padding-right: ${dimensions.grid.gutter / 2}px;

  .no-gutter & {
    padding-left: 0;
    padding-right: 0;
  }

  ${mediaQueries.MAX_SM} {
    max-width: 100%;
  }
  ${mediaQueries.MIN_MD} {
    flex-basis: ${(props) => colSize[props.md]};
    padding-left: ${dimensions.grid.gutter}px;
    padding-right: ${dimensions.grid.gutter}px;
  }
  ${mediaQueries.MIN_LG} {
    flex-basis: ${(props) => colSize[props.lg]};
  }
  ${mediaQueries.MIN_XL} {
    flex-basis: ${(props) => colSize[props.xl]};
    margin-left: ${(props) => (props.pushRight ? '12.5%' : 'inherit')};
  }
`;

class Col extends React.Component {
  render() {
    const {children} = this.props;

    return <GridCol {...this.props}>{children}</GridCol>;
  }
}

Col.propTypes = {
  children: ChildrenType.isRequired,
};

export default Col;
