// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSmall = styled.small`
  font-family: 'proxima-nova', 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: 1px;
`;

const TextSmallCaps = (props) => (
  <StyledSmall {...props}>{props.children}</StyledSmall>
);

TextSmallCaps.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextSmallCaps;
