// @flow
const styles = {
  colors: {
    red: '#ef4b3b',
    purple: '#5b64f7',
    palePurple: 'rgba(91, 100, 247, 0.025)',
    paleOrange: '#fff5e8',
    darkBlue: '#212733',
    offwhite: '#f1f1f1',
    greyBg: '#22222a',
    greyBody: '#7e7e8f',
    greySubdued: 'rgba(255, 255, 255, 0.64)', // input *placeholder* text colour
    greyBorder: '#ececf2',
    white: '#ffffff',
  },
  spacing: {
    vertical: '2.5',
  },
  shadows: {
    videoBox: '0 12px 48px 8px rgba(0, 0, 0, 0.25)',
    card: '0 8px 32px 0 rgba(33, 39, 51, 0.08)',
    cardHover: '0 8px 36px 0 rgba(33, 39, 51, 0.2)',
    banner: '0 6px 28px 0 rgba(33, 39, 51, 0.06)',
  },
  gradients: {
    brandGradient: 'linear-gradient(to right, #f74431, #e32869)',
  },
  transitions: {
    fastHover: 'all 0.25s cubic-bezier(0.2, 1, 0.4, 1)',
    slowHover: 'all 0.5s cubic-bezier(0.2, 1, 0.4, 1)',
  },
};

export default styles;
