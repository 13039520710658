// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import strings from '../config/strings.json';
import {mediaQueries} from '../theme';
import {colors} from '../theme/colors';
import {matchCurrentNav} from '../utils/matchCurrentNav';

import Link from './Link';
import TextSmallCaps from './TextSmallCaps';

const StyledLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 0.5rem;
    ${mediaQueries.MIN_MD} {
      margin-right: 1rem;
    }
    ${mediaQueries.MIN_LG} {
      margin-right: 1.25rem;
    }
  }

  &.active,
  &:hover,
  &:focus {
    color: ${colors.brandPrimary};
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  * {
    text-decoration: none;
  }

  &.primary a:first-child {
    ${mediaQueries.MIN_MD} {
      display: none;
    }
  }

  &.secondary a:not(:hover):not(:focus) {
    color: ${colors.black60};
  }
`;

const linkBuilder = (path, slug, onClick) => {
  let destination = `/${slug}/`;
  if (slug === 'home') {
    destination = '/';
  }
  const isCurrentNav = matchCurrentNav(destination, path);

  return (
    <StyledLink
      onClick={onClick}
      to={destination}
      title={strings.pages[slug].nav.title}
      className={['noDecoration', isCurrentNav && 'active']}
      data-testid-link={strings.pages[slug].nav.text}
    >
      <TextSmallCaps>{strings.pages[slug].nav.text}</TextSmallCaps>
    </StyledLink>
  );
};

class MenuLinks extends React.Component {
  render() {
    const {variant, path, onClick} = this.props;

    if (variant === 'primary') {
      return (
        <LinksWrapper className={variant}>
          {linkBuilder(path, 'home', onClick)}
          {linkBuilder(path, 'about', onClick)}
          <StyledLink
            to="https://playbook.hanno.co"
            title="SaMD Playbook"
            className="noDecoration"
          >
            <TextSmallCaps>Playbook</TextSmallCaps>
          </StyledLink>
        </LinksWrapper>
      );
    } else if (variant === 'secondary')
      return (
        <LinksWrapper className={variant}>
          {linkBuilder(path, 'compliance', onClick)}
        </LinksWrapper>
      );
  }
}

MenuLinks.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

export default MenuLinks;
