// @flow
const breakpoints = {
  xs: '375',
  sm: '568',
  md: '768',
  lg: '1024',
  xl: '1366',
  xxl: '1680',
  fhd: '1920',
  qhd: '2560',
  uhd: '3840',
};

const mq = `@media only screen`;
const landscape = `(orientation:landscape)`;

const minWidth = (breakpoint) => `and (min-width:${breakpoint}px)`;
const maxWidth = (breakpoint) => `and (max-width:${breakpoint - 1}px)`;
const maxHeight = (breakpoint) => `and (max-height:${breakpoint - 1}px)`;

const mediaQueries = {
  // Maximum media queries
  MAX_XS: `${mq} ${maxWidth(breakpoints.xs)}`,
  MAX_SM: `${mq} ${maxWidth(breakpoints.sm)}`,
  MAX_SM_LANDSCAPE: `${mq} ${maxWidth(breakpoints.md)}`,
  MAX_MD: `${mq} ${maxWidth(breakpoints.lg)}`,
  MAX_LG: `${mq} ${maxWidth(breakpoints.xl)}`,
  MAX_XL: `${mq} ${maxWidth(breakpoints.xxl)}`,
  // Minimum media queries
  MIN_XS: `${mq} ${minWidth(breakpoints.xs)}`,
  MIN_SM: `${mq} ${minWidth(breakpoints.sm)}`,
  MIN_MD: `${mq} ${minWidth(breakpoints.md)}`,
  MIN_LG: `${mq} ${minWidth(breakpoints.lg)}`,
  MIN_XL: `${mq} ${minWidth(breakpoints.xl)}`,
  MIN_XXL: `${mq} ${minWidth(breakpoints.xxl)}`,
  // Precise range media queries
  XS_ONLY: `${mq} ${minWidth(breakpoints.xs)} ${maxWidth(breakpoints.sm)}`,
  SM_ONLY: `${mq} ${minWidth(breakpoints.sm)} ${maxWidth(breakpoints.md)}`,
  MD_ONLY: `${mq} ${minWidth(breakpoints.md)} ${maxWidth(breakpoints.lg)}`,
  LG_ONLY: `${mq} ${minWidth(breakpoints.lg)} ${maxWidth(breakpoints.xl)}`,
  XL_ONLY: `${mq} ${minWidth(breakpoints.xl)}`,
  //
  MOBILE_LANDSCAPE: `${mq} ${maxHeight(breakpoints.sm)} and ${landscape}`,
  FHD: `${mq} ${minWidth(breakpoints.fhd)}`,
  QHD: `${mq} ${minWidth(breakpoints.qhd)}`,
  UHD: `${mq} ${minWidth(breakpoints.uhd)}`,
};

export default mediaQueries;
