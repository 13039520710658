// @flow
import {rgba} from 'polished';
import Typography from 'typography';

import {colors, mediaQueries} from '../theme';
import styles from '../theme/styles';

const fontFamilyPrimary = ['freight-text-pro', 'serif'];

const typography = new Typography({
  baseFontSize: '24px',
  baseLineHeight: 1.55, // Rhythm base is therefore 24 * 1.5 = 36px or 1.5rem
  boldWeight: 600,
  headerWeight: 400,
  headerFontFamily: fontFamilyPrimary,
  headerColor: colors.blackSoft,
  bodyWeight: 400,
  bodyFontFamily: fontFamilyPrimary,
  bodyColor: colors.blackSoft,
  scaleRatio: 4,

  overrideStyles: ({rhythm}) => ({
    body: {
      wordWrap: 'normal',
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
    },
    'body.backgroundOffwhite': {
      backgroundColor: colors.offWhite,
    },
    'h1, h2, h3, h4, h5, h6': {
      width: '100%', // fixes IE 11 wrap issue inside flexbox
      transform: 'translateX(-1px)',
    },
    'h1, h2': {
      transform: 'translateX(-2px)',
    },
    h1: {
      fontSize: '4rem',
      lineHeight: 1,
      marginBottom: rhythm(1),
      letterSpacing: '-0.2px',
    },
    h2: {
      fontSize: '3rem',
      lineHeight: 1,
      marginBottom: rhythm(2 / 3),
      letterSpacing: '-0.1px',
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.2,
      marginBottom: rhythm(1),
      letterSpacing: '-0.2px',
    },
    h4: {
      fontSize: '1.3333333333rem',
      lineHeight: '1.25 !important',
      marginBottom: rhythm(1 / 2),
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.083333333333rem',
      lineHeight: '1.25 !important',
      marginBottom: rhythm(1 / 4.5),
      fontWeight: 500,
      letterSpacing: '-0.1px',
    },
    'h4, h5': {
      marginTop: rhythm(2 / 3),
    },
    small: {fontSize: '0.75rem'},
    p: {marginBottom: rhythm(1)},
    li: {marginBottom: rhythm(1 / 4)},
    'p:last-child, li:last-child': {marginBottom: '0'},
    'p + h3': {
      marginTop: rhythm(3 / 2),
    },
    'h3 + p': {
      marginTop: `-${rhythm(1 / 3)}`,
    },
    a: {
      color: 'inherit',
      textDecoration: 'underline',
      transition: styles.transitions.fastHover,
    },
    'a:visited': {
      color: colors.blackSoft,
    },
    'a:hover, a:focus': {
      color: colors.brandPrimary,
      outline: 'none',
      textDecoration: 'underline',
      transition: styles.transitions.fastHover,
    },
    'a.noDecoration': {
      textDecoration: 'none',
    },
    ul: {
      marginLeft: '1rem',
      marginBottom: rhythm(4 / 3),
    },
    blockquote: {
      margin: `${rhythm(4 / 3)} 0 ${rhythm(2 / 3)}`,
      padding: 0,
      fontSize: '1.3333333333rem',
      fontStyle: 'italic',
    },
    'blockquote + blockquote': {
      marginTop: 0,
    },
    hr: {
      width: '100%',
      background: 'rgba(186, 186, 194, 0.3)', // TODO
    },
    '::selection': {
      backgroundColor: rgba(colors.brandPrimary, 0.07),
    },
    'form ::selection': {
      backgroundColor: rgba(colors.brandPrimary, 0.1),
    },
    [mediaQueries.MAX_LG]: {
      body: {fontSize: '22px'},
      h1: {fontSize: '3.5rem'},
      h2: {fontSize: '2.75rem'},
    },
    [mediaQueries.MAX_MD]: {
      body: {fontSize: '20px'},
      h1: {fontSize: '2.5rem', marginBottom: rhythm(1 / 3)},
      h2: {fontSize: '2.25rem', marginBottom: rhythm(1 / 3)},
      h3: {fontSize: '1.5rem'},
      h4: {fontSize: '1.125rem'},
      h5: {fontSize: '1rem'},
      p: {marginBottom: rhythm(2 / 3)},
      ul: {marginBottom: rhythm(2 / 3)},
      blockquote: {
        fontSize: '1rem',
      },
    },
    [mediaQueries.MAX_SM]: {
      body: {fontSize: '18px'},
      h1: {fontSize: '2.125rem'},
      h2: {fontSize: '1.875rem'},
      h3: {fontSize: '1.35rem', fontWeight: 500},
      h4: {fontSize: '1.025rem'},
      h5: {fontSize: '0.875rem', fontWeight: 500},
      p: {marginBottom: rhythm(1 / 2)},
      blockquote: {
        fontSize: '0.875rem',
        marginTop: rhythm(2 / 3),
        marginBottom: rhythm(2 / 5),
        marginLeft: 0,
      },
    },
  }),
});

const {rhythm, scale} = typography;

// eslint-disable-next-line sort-exports/sort-exports
export {rhythm, scale, typography as default};
