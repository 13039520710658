// @flow
import {rhythm} from '../utils/typography';

const dimensions = {
  grid: {
    gutter: 24,
    containerWidth: {
      sm: 540,
      md: 740,
      lg: 994,
      xl: 1334,
      xxl: 1536,
    },
  },
  spacing: {
    small: rhythm(1 / 2),
    default: rhythm(1),
    vertical: {
      medium: rhythm(1.75),
      large: rhythm(3.5),
    },
    horizontal: {
      medium: rhythm(4 / 3),
      large: rhythm(3),
    },
  },
  contentWidth: {
    small: 366,
    medium: 666,
    default: 756,
    large: 978,
  },
  image: {
    small: 147,
    medium: 250,
    large: 318,
    articleFeatured: 1200, // Article featured images, at the top of posts
    articleFeaturedHeight: 700, // Source image height for article featured images; should be approximately the same as video dimensions
    largeRectangularWidth: 490, // Used for the Journal Highlights, which are rectangular format
    avatar: 96,
    articleStandard: 900, // Article standard images, being cropped to column width
  },
  borderRadius: '2px',
  icon: {
    small: '16px',
    large: '32px',
  },
  buttonHeight: '54px',
};

export default dimensions;
