// @flow
const brandColors = {
  brandPrimary: '#ea4d47',
  blackHard: '#1b1b1b',
  blackSoft: '#303030',
  black80: '#595959',
  black60: '#838383',
  black40: '#acacac',
  black20: '#d6d6d6',
};

// TODO: Sergei to decide what to do about this file, given that it has a lot of overlap with the styles file in the same directory

export const colors = {
  ...brandColors,
  // Text elements
  textDefault: brandColors.blackSoft,
  // Backgrounds
  offWhite: '#fbfbfb',
};
