// @flow
import React from 'react';
import styled from 'styled-components';

import LogoSvg from '../assets/icons/logo.svg';
import {colors} from '../theme/colors';

// Wide color gamut (p3) is currently only supported in Safari.
// We make use of it to display a punchier red color of the logo.
// See it in action on mobile Safari (by default) or on a desktop Safari,
// provided that OS-wide color space is set to Display P3.
// Otherwise, we fall back to our default sRGB color
const StyledLogo = styled(LogoSvg)`
  .logo_rect {
    fill: ${colors.brandPrimary};

    @media (color-gamut: p3) {
      fill: color(display-p3 0.91 0.28 0.26);
    }
  }
`;

const Logo = (props) => <StyledLogo {...props} />;

export default Logo;
