// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import IconPlay from '../assets/icons/play.svg'; // TODO: Any icons like this should live in a single file and be imported like this: import { PlayCircle } from '../icons'
import IconClose from '../assets/icons/player-close.svg';
import IconPause from '../assets/icons/player-pause.svg';
import {colors, mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import styles from '../theme/styles';
import {rhythm} from '../utils/typography';

import TextSmallCaps from './TextSmallCaps';

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${dimensions.buttonHeight};
  padding: 0 ${rhythm(2 / 3)};
  color: ${colors.textDefault};
  background-color: #fff;
  border: none;
  box-sizing: border-box;

  small {
    transform: translateY(1px);
  }

  ${mediaQueries.MAX_SM} {
    width: 100%;
  }

  .buttonFrame {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0 0 0 1px ${colors.blackSoft};
    transform: scale(1);
    border-radius: ${dimensions.borderRadius};
    transition: ${styles.transitions.fastHover};
  }

  &:hover {
    transition: ${styles.transitions.fastHover};

    .buttonFrame {
      box-shadow: inset 0 0 0 2px ${colors.blackSoft};
      border-radius: 0;
    }
  }

  &:focus,
  &:active {
    outline: none;

    .buttonFrame {
      box-shadow: inset 0 0 0 3px ${colors.blackSoft};
    }
  }

  &:active {
    .buttonFrame {
      transform: scale(0.95);
    }
  }

  &.secondary:not(:hover):not(:focus):not(:active) .buttonFrame {
    box-shadow: 0 0 0 1px ${colors.black20};
  }
  &.inverted:not(:hover):not(:focus):not(:active) .buttonFrame {
    box-shadow: 0 0 0 1px ${colors.black80};
  }

  svg {
    margin-right: ${rhythm(1 / 3)};
    width: ${dimensions.icon.small};
    height: ${dimensions.icon.small};
    transform: translateY(-1px);
  }

  &.inverted {
    background-color: transparent;
    color: #fff;

    svg * {
      fill: #fff;
    }
  }
`;

const Button = (props) => (
  <StyledButton
    type="submit"
    aria-label={props.label}
    onClick={props.onClick}
    className={[
      props.isSecondary ? 'secondary' : 'primary',
      props.isInverted && 'inverted',
      props.className,
    ].join(' ')}>
    {props.variant && props.variant === 'playButton' && <IconPlay />}
    {props.variant && props.variant === 'closeButton' && <IconClose />}
    {props.variant && props.variant === 'pauseButton' && <IconPause />}
    {props.children && <TextSmallCaps>{props.children}</TextSmallCaps>}
    <span className="buttonFrame" />
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  isSecondary: PropTypes.bool,
  isInverted: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  isSecondary: false,
  isInverted: false,
  onClick: null,
  label: '',
  className: '',
};

export default Button;
