// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import strings from '../config/strings.json';
import {mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {PathType} from '../types';
import {rhythm} from '../utils/typography';

import Col from './Col';
import GridContainer from './GridContainer';
import Link from './Link';
import Logo from './Logo';
import MobileMenu from './MobileMenu';
import Row from './Row';
import Visible from './Visible';

const StyledNav = styled.nav`
  width: 100%;
  padding: ${dimensions.spacing.small} 0;

  ${mediaQueries.MIN_LG} {
    padding: ${dimensions.spacing.default};
  }
  ${mediaQueries.MIN_XL} {
    padding: ${dimensions.spacing.default}
      ${dimensions.spacing.horizontal.large};
  }

  ${mediaQueries.MAX_SM_LANDSCAPE} {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
  }
`;

const StyledSpacer = styled.div`
  height: 96px;
  display: block;
  width: 100%;
`;

const Lockup = styled(Col)`
  button {
    display: flex;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    background: none;
  }
`;

const StyledLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  span {
    margin-left: ${rhythm(2 / 3)};
    font-size: 18px;
  }
`;

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = (e) => {
    this.openMenu();

    e.stopPropagation();
  };

  openMenu = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (e) => {
    this.closeMenu();

    e.stopPropagation();
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {hidesStrapline = false} = this.props;

    return (
      <React.Fragment>
        <GridContainer variant="fullScreen">
          <Visible breakpoints="xs sm">
            <StyledSpacer />
          </Visible>
          <StyledNav>
            <Row justifyBetween alignCenter>
              <Lockup className="md-4">
                <Row alignCenter>
                  <Visible breakpoints="xs sm">
                    <MobileMenu
                      open={this.state.open}
                      handleClose={this.handleClose}
                    />
                    <a
                      onClick={this.handleOpen}
                      role="button"
                      aria-controls="mobileMenu"
                      aria-label="Open menu"
                    >
                      <Logo />
                    </a>
                  </Visible>
                  <Visible breakpoints="md lg xl">
                    <StyledLogoLink to="/" aria-label="Go to homepage">
                      <Logo data-testid="logo" />
                      {!hidesStrapline && (
                        <span>{strings.global.strapline}</span>
                      )}
                    </StyledLogoLink>
                  </Visible>
                </Row>
              </Lockup>
            </Row>
          </StyledNav>
        </GridContainer>
      </React.Fragment>
    );
  }
}

Nav.propTypes = {
  path: PathType,
  hidesStrapline: PropTypes.bool,
};

export default Nav;
