// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {colors, mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {rhythm} from '../utils/typography';

import Button from './Button';
import MenuLinks from './MenuLinks';

const MENU_WIDTH = 250;
const GUTTER_SPACING_TO_MATCH = dimensions.grid.gutter;
const LOGO_BUTTON_WIDTH = 48;

const OVERLAY_COLOR = 'rgba(131, 131, 131, 0.9)';
const EASING = 'cubic-bezier(0.42, 0, 0.34, 1)';

const Menu = styled.nav`
  display: flex;
  visibility: visible;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 8886;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: ${(props) => (props.open ? 'initial' : 'none')};
  overflow: hidden;

  ${mediaQueries.MIN_MD} {
    display: none;
    visibility: hidden;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${OVERLAY_COLOR};
  opacity: ${(props) => (props.open ? '1' : '0')};
  transition: opacity 0.15s 0.15s ease-in-out;
  z-index: 8887;
`;

const StyledContentWrapper = styled.div`
  position: relative;
  background-color: #fff;
  height: 100%;
  text-align: left;
  padding: ${rhythm(1 / 2)} ${rhythm(1)} 0;
  width: ${MENU_WIDTH}px;
  z-index: 8888;

  ${mediaQueries.MIN_SM} {
    padding: ${rhythm(1)} ${rhythm(1)} 0;
  }

  opacity: ${(props) => (props.open ? 1 : 0)};

  transform: ${(props) =>
    props.open
      ? 'translateX(0)'
      : `translateX(-${MENU_WIDTH - GUTTER_SPACING_TO_MATCH / 2}px)`};
  transition: transform 0.3s 0.15s ${EASING}, opacity 0.2s 0.2s ${EASING};

  > svg,
  button {
    position: absolute;
    right: -${LOGO_BUTTON_WIDTH}px;
    top: ${dimensions.spacing.small};
  }

  > svg {
    pointer-events: none;
    z-index: 8888;
  }

  button {
    z-index: 8889;
    width: ${LOGO_BUTTON_WIDTH}px;
    height: ${LOGO_BUTTON_WIDTH}px;
    padding: 0;
    background-color: ${colors.brandPrimary};

    svg {
      margin: 0;
      width: ${LOGO_BUTTON_WIDTH / 2}px;
      height: ${LOGO_BUTTON_WIDTH / 2}px;
      fill: #fff;
      position: relative;
      left: -1px;
    }

    .buttonFrame {
      display: none;
    }

    opacity: ${(props) => (props.open ? 1 : 0)};
    transition: opacity 0.3s linear;
    pointer-events: ${(props) => (props.open ? 'initial' : 'none')};
  }

  a {
    display: block;
    margin: ${rhythm(1 / 3)} 0;
    width: 100%;
    text-decoration: none;

    @media screen and (max-height: 479px) {
      margin: ${rhythm(1 / 5)} 0;
    }

    small {
      font-size: ${rhythm(2 / 5)};
    }
  }
`;

const MobileMenu = ({open, handleClose, ...props}) => {
  return (
    <Menu open={open} role="navigation" {...props}>
      <StyledOverlay open={open} onClick={handleClose} />
      <StyledContentWrapper open={open} id="mobileMenu">
        <MenuLinks variant="primary" onClick={handleClose} />
        <MenuLinks variant="secondary" onClick={handleClose} />
        <Button
          variant="closeButton"
          label="Close menu"
          onClick={handleClose}
        />
      </StyledContentWrapper>
    </Menu>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

export default MobileMenu;
